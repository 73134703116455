import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
//import VueCompositionAPI from "@vue/composition-api";

import router from "./router";
import store from "./store";
import App from "./App.vue";
import "./types/api-orisis/directives/directives.js";

import "material-icons/iconfont/material-icons.css"; //Material Icons

import RouterTab from "vue-router-tab";
import "vue-router-tab/dist/lib/vue-router-tab.css";

import vuetify from "./plugins/vuetify.js";
// Global Components
import "./global-components";
import axios from "axios";
// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import VTooltip from "v-tooltip";
import Vuesax from "vuesax";
import VueCryptojs from "vue-cryptojs";

Vue.use(VueCryptojs);
// import 'vuesax/dist/vuesax.css' //Vuesax styles

Vue.use(Vuesax, {
  // options here
});

Vue.use(VTooltip, {
  themes: {
    orisis: {
      $extend: "dropdown",
      triggers: ["hover"],
      autoHide: true,
      eagerMount: true,
      placement: "bottom",
      strategy: "fixed",
      instantMove: true,
      handleResize: true,
      html: true,
    },
  },
});

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(VueSweetalert2);
Vue.use(ModalPlugin);

// Composition API
//Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

require("@/assets/vuesax.css");
// import assets styles
require("@/assets/scss/style.scss");
// import 'v-tooltip/dist/v-tooltip.css'
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);
import VPopover from "vue-js-popover";
Vue.use(VPopover, { tooltip: true });
Vue.config.productionTip = false;
Vue.use(RouterTab);
// import {store} from 'vuex'

import "ag-grid-enterprise";

import "bootstrap/scss/bootstrap.scss";

import "leaflet/dist/leaflet.css";

import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCDYeBuipOfrJl4SYCBI8q-NEF8cCGbvIE",
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
});

// Intercept Axios Response
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.data?.message) {
      store.dispatch("setIsGlobalLoading", false);
      vm.$bvToast.toast(error.response.data.message, {
        title: `Erreur ${error.response.status || ""}`,
        variant: "danger",
        solid: true,
        // toaster: 'b-toaster-bottom-center',
      });
    } else if (error.response.status === 401) {
      if (error.response.config.url.includes("Account/Login")) {
        store.dispatch(
          "setIsLogginMsg",
          "Identifiant ou mot de passe incorrect, veuillez recommencer."
        );
        store.dispatch("setIsGlobalLoading", false);
        // vm.$bvToast.toast("Identifiant ou mot de passe incorrect", {
        //   title: `Erreur d'authentification`,
        //   variant: "danger",
        //   solid: true,
        // })
      } else {
        if (!store.getters.isToastingNotification) {
          if (router.path?.includes("/login")) {
            store.dispatch(
              "setIsLogginMsg",
              "Identifiant ou mot de passe incorrect, veuillez recommencer."
            );
            store.dispatch("setIsGlobalLoading", false);
            // vm.$bvToast.toast("Identifiant ou mot de passe incorrect !", {
            //   title: `Erreur d'authentification`,
            //   variant: "danger",
            //   solid: true,
            // })
          } else {
            vm.$bvToast.toast(
              "Merci de vous reconnecter avec vos identifiants CHECK MY POOL.",
              {
                title: `Erreur d'authentification`,
                variant: "danger",
                solid: true,
              }
            );
          }
        }
        store.dispatch("setIsToastingNotification", true);
        store.dispatch("logOut");
        if (!router.path || !router.path?.includes("/login")) {
          router.push("/login");
          store.dispatch("setIsGlobalLoading", false);
        }
      }
    } else if (error.response.status === 404) {
      store.dispatch("setIsToastingNotification", true);
      vm.$bvToast.toast(
        "Une erreur s'est produite, merci de contacter le support CHECK MY POOL.",
        {
          title: `Erreur d'accès`,
          variant: "danger",
          solid: true,
        }
      );
    } else {
      store.dispatch("setIsToastingNotification", true);
      vm.$bvToast.toast(
        "Une erreur s'est produite, merci de contacter le support CHECK MY POOL.",
        {
          title: `Erreur système`,
          variant: "danger",
          solid: true,
        }
      );
    }
    return Promise.reject(error);
  }
);

Vue.component("v-style", {
  render: function (createElement) {
    return createElement("style", this.$slots.default);
  },
});

export const vm = new Vue({
  el: "#app",
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
