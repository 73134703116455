import { IFileState } from "./states"
import { Module } from "vuex"
import { FileResponseDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IFileState = {
    filesList : Array<FileResponseDto>(),
    filesListArchived : Array<FileResponseDto>(),
    file      : <FileResponseDto>{},
    isLoadingFilesList    : false,
    isLoadingFilesListArchived    : false,
    isLoadingFile    : false,
    isCreatingFile   : false,
    isUpdatingFile   : false,
    isUpdatingStepFile   : false,
    isDeletingFile   : false,
    requestStateFile : <IRequestState>{},
    isOpenSidebarFile : false,
    isArchivingFile : false,
}

export const FileModule: Module<IFileState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}