import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { MissionTypeResponseDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IMissionTypeState } from "./states";

export enum MissionTypeMutations {
    SET_MISSION_TYPES_LIST = "SET_MISSION_TYPES_LIST",
    SET_MISSION_TYPES_LIST_ARCHIVED = "SET_MISSION_TYPES_LIST_ARCHIVED",
    SET_MISSION_TYPE = "SET_MISSION_TYPE",

    // COMMON
    SET_IS_LOADING_MISSION_TYPES_LIST= "SET_IS_LOADING_MISSION_TYPES_LIST",
    SET_IS_LOADING_MISSION_TYPES_LIST_ARCHIVED= "SET_IS_LOADING_MISSION_TYPES_LIST_ARCHIVED",
    
    SET_IS_LOADING_MISSION_TYPE= "SET_IS_LOADING_MISSION_TYPE",
    SET_IS_CREATING_MISSION_TYPE = "SET_IS_CREATING_MISSION_TYPE",
    SET_IS_UPDATING_MISSION_TYPE= "SET_IS_UPDATING_MISSION_TYPE",
    SET_IS_DELETING_MISSION_TYPE = "SET_IS_DELETING_MISSION_TYPE",
    SET_REQUEST_STATE_MISSION_TYPE = "SET_REQUEST_STATE_MISSION_TYPE",
    SET_IS_OPEN_SIDEBAR_MISSION_TYPE = "SET_IS_OPEN_SIDEBAR_MISSION_TYPE",
    SET_IS_ARCHIVING_MISSION_TYPE = "SET_IS_ARCHIVING_MISSION_TYPE"
}

export const mutations: MutationTree<IMissionTypeState> = {
    [MissionTypeMutations.SET_MISSION_TYPES_LIST]: (state, payload: MissionTypeResponseDto[]) => {
        state.missionTypesList = payload;
    },
    [MissionTypeMutations.SET_MISSION_TYPES_LIST_ARCHIVED]: (state, payload: MissionTypeResponseDto[]) => {
        state.missionTypesListArchived = payload;
    },
    [MissionTypeMutations.SET_MISSION_TYPE]: (state, payload: MissionTypeResponseDto) => {
        state.missionType = payload;
    },


    //COMMON
    [MissionTypeMutations.SET_IS_LOADING_MISSION_TYPES_LIST]: (state, payload: boolean) => {
        state.isLoadingMissionTypesList = payload;
    },
    [MissionTypeMutations.SET_IS_LOADING_MISSION_TYPES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingMissionTypesListArchived = payload;
    },
    [MissionTypeMutations.SET_IS_LOADING_MISSION_TYPE]: (state, payload: boolean) => {
        state.isLoadingMissionType = payload;
    },
    [MissionTypeMutations.SET_IS_CREATING_MISSION_TYPE]: (state, payload: boolean) => {
        state.isCreatingMissionType = payload;
    },
    [MissionTypeMutations.SET_IS_UPDATING_MISSION_TYPE]: (state, payload: boolean) => {
        state.isUpdatingMissionType = payload;
    },
    [MissionTypeMutations.SET_IS_DELETING_MISSION_TYPE]: (state, payload: boolean) => {
        state.isDeletingMissionType = payload;
    },
    [MissionTypeMutations.SET_REQUEST_STATE_MISSION_TYPE]: (state, payload: IRequestState) => {
        state.requestStateMissionType = payload;
    },
    [MissionTypeMutations.SET_IS_OPEN_SIDEBAR_MISSION_TYPE]: (state, payload: boolean) => {
        state.isOpenSidebarMissionType = payload;
    },
    [MissionTypeMutations.SET_IS_ARCHIVING_MISSION_TYPE]: (state, payload: boolean) => {
        state.isArchivingMissionType = payload;
    },
}