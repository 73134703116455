import { IContractMaintenanceTypeState } from "./states"
import { Module } from "vuex"
import { ContractTypeResponseDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IContractMaintenanceTypeState = {
    contractMaintenanceTypesList : Array<ContractTypeResponseDto>(),
    contractMaintenanceTypesListArchived : Array<ContractTypeResponseDto>(),
    contractMaintenanceType      : <ContractTypeResponseDto>{},
    isLoadingContractMaintenanceTypesList    : false,
    isLoadingContractMaintenanceTypesListArchived    : false,
    isLoadingContractMaintenanceType    : false,
    isCreatingContractMaintenanceType   : false,
    isUpdatingContractMaintenanceType   : false,
    isDeletingContractMaintenanceType   : false,
    requestStateContractMaintenanceType : <IRequestState>{},
    isOpenSidebarContractMaintenanceType : false,
    isArchivingContractMaintenanceType : false,
}

export const ContractMaintenanceTypeModule: Module<IContractMaintenanceTypeState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}