import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { DeviceResponseDto } from "@/../Api";
import { MutationTree } from "vuex";
import { IDeviceState } from "./states";

export enum DeviceMutations {
  SET_DEVICES_LIST = "SET_DEVICES_LIST",
  SET_DEVICES_LIST_ARCHIVED = "SET_DEVICES_LIST_ARCHIVED",
  SET_DEVICE = "SET_DEVICE",
  SET_DEVICES_LIST_BY_POOL_ID = "SET_DEVICES_LIST_BY_POOL_ID",

  // COMMON
  SET_IS_LOADING_DEVICES_LIST = "SET_IS_LOADING_DEVICES_LIST",
  SET_IS_LOADING_DEVICES_LIST_BY_POOL_ID = "SET_IS_LOADING_DEVICES_LIST_BY_POOL_ID",
  SET_IS_LOADING_DEVICES_LIST_ARCHIVED = "SET_IS_LOADING_DEVICES_LIST_ARCHIVED",

  SET_IS_LOADING_DEVICE = "SET_IS_LOADING_DEVICE",
  SET_IS_CREATING_DEVICE = "SET_IS_CREATING_DEVICE",
  SET_IS_UPDATING_DEVICE = "SET_IS_UPDATING_DEVICE",
  SET_IS_DELETING_DEVICE = "SET_IS_DELETING_DEVICE",
  SET_REQUEST_STATE_DEVICE = "SET_REQUEST_STATE_DEVICE",
  SET_IS_OPEN_SIDEBAR_DEVICE = "SET_IS_OPEN_SIDEBAR_DEVICE",
  SET_IS_ARCHIVING_DEVICE = "SET_IS_ARCHIVING_DEVICE",
}

export const mutations: MutationTree<IDeviceState> = {
  [DeviceMutations.SET_DEVICES_LIST]: (state, payload: DeviceResponseDto[]) => {
    state.devicesList = payload;
  },
  [DeviceMutations.SET_DEVICES_LIST_BY_POOL_ID]: (
    state,
    payload: DeviceResponseDto[]
  ) => {
    state.devicesListByPoolId = payload;
  },
  [DeviceMutations.SET_DEVICES_LIST_ARCHIVED]: (
    state,
    payload: DeviceResponseDto[]
  ) => {
    state.devicesListArchived = payload;
  },
  [DeviceMutations.SET_DEVICE]: (state, payload: DeviceResponseDto) => {
    state.device = payload;
  },

  //COMMON
  [DeviceMutations.SET_IS_LOADING_DEVICES_LIST]: (state, payload: boolean) => {
    state.isLoadingDevicesList = payload;
  },
  [DeviceMutations.SET_IS_LOADING_DEVICES_LIST_BY_POOL_ID]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDevicesListByPoolId = payload;
  },

  [DeviceMutations.SET_IS_LOADING_DEVICES_LIST_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDevicesListArchived = payload;
  },
  [DeviceMutations.SET_IS_LOADING_DEVICE]: (state, payload: boolean) => {
    state.isLoadingDevice = payload;
  },
  [DeviceMutations.SET_IS_CREATING_DEVICE]: (state, payload: boolean) => {
    state.isCreatingDevice = payload;
  },
  [DeviceMutations.SET_IS_UPDATING_DEVICE]: (state, payload: boolean) => {
    state.isUpdatingDevice = payload;
  },
  [DeviceMutations.SET_IS_DELETING_DEVICE]: (state, payload: boolean) => {
    state.isDeletingDevice = payload;
  },
  [DeviceMutations.SET_REQUEST_STATE_DEVICE]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateDevice = payload;
  },
  [DeviceMutations.SET_IS_OPEN_SIDEBAR_DEVICE]: (state, payload: boolean) => {
    state.isOpenSidebarDevice = payload;
  },
  [DeviceMutations.SET_IS_ARCHIVING_DEVICE]: (state, payload: boolean) => {
    state.isArchivingDevice = payload;
  },
};
