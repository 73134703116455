import { IPoolTypeState } from "./states"
import { Module } from "vuex"
import { PoolTypeResponseDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IPoolTypeState = {
    poolTypesList : Array<PoolTypeResponseDto>(),
    poolTypesListArchived : Array<PoolTypeResponseDto>(),
    poolType      : <PoolTypeResponseDto>{},
    isLoadingPoolTypesList    : false,
    isLoadingPoolTypesListArchived    : false,
    isLoadingPoolType    : false,
    isCreatingPoolType   : false,
    isUpdatingPoolType   : false,
    isDeletingPoolType   : false,
    requestStatePoolType : <IRequestState>{},
    isOpenSidebarPoolType : false,
    isArchivingPoolType : false,
}

export const PoolTypeModule: Module<IPoolTypeState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}