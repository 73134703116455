import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { PoolAccessTypeResponseDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IPoolAccessTypeState } from "./states";

export enum PoolAccessTypeMutations {
    SET_POOL_ACCESS_TYPES_LIST = "SET_POOL_ACCESS_TYPES_LIST",
    SET_POOL_ACCESS_TYPES_LIST_ARCHIVED = "SET_POOL_ACCESS_TYPES_LIST_ARCHIVED",
    SET_POOL_ACCESS_TYPE = "SET_POOL_ACCESS_TYPE",

    // COMMON
    SET_IS_LOADING_POOL_ACCESS_TYPES_LIST= "SET_IS_LOADING_POOL_ACCESS_TYPES_LIST",
    SET_IS_LOADING_POOL_ACCESS_TYPES_LIST_ARCHIVED= "SET_IS_LOADING_POOL_ACCESS_TYPES_LIST_ARCHIVED",
    
    SET_IS_LOADING_POOL_ACCESS_TYPE= "SET_IS_LOADING_POOL_ACCESS_TYPE",
    SET_IS_CREATING_POOL_ACCESS_TYPE = "SET_IS_CREATING_POOL_ACCESS_TYPE",
    SET_IS_UPDATING_POOL_ACCESS_TYPE= "SET_IS_UPDATING_POOL_ACCESS_TYPE",
    SET_IS_DELETING_POOL_ACCESS_TYPE = "SET_IS_DELETING_POOL_ACCESS_TYPE",
    SET_REQUEST_STATE_POOL_ACCESS_TYPE = "SET_REQUEST_STATE_POOL_ACCESS_TYPE",
    SET_IS_OPEN_SIDEBAR_POOL_ACCESS_TYPE = "SET_IS_OPEN_SIDEBAR_POOL_ACCESS_TYPE",
    SET_IS_ARCHIVING_POOL_ACCESS_TYPE = "SET_IS_ARCHIVING_POOL_ACCESS_TYPE"
}

export const mutations: MutationTree<IPoolAccessTypeState> = {
    [PoolAccessTypeMutations.SET_POOL_ACCESS_TYPES_LIST]: (state, payload: PoolAccessTypeResponseDto[]) => {
        state.poolAccessTypesList = payload;
    },
    [PoolAccessTypeMutations.SET_POOL_ACCESS_TYPES_LIST_ARCHIVED]: (state, payload: PoolAccessTypeResponseDto[]) => {
        state.poolAccessTypesListArchived = payload;
    },
    [PoolAccessTypeMutations.SET_POOL_ACCESS_TYPE]: (state, payload: PoolAccessTypeResponseDto) => {
        state.poolAccessType = payload;
    },


    //COMMON
    [PoolAccessTypeMutations.SET_IS_LOADING_POOL_ACCESS_TYPES_LIST]: (state, payload: boolean) => {
        state.isLoadingPoolAccessTypesList = payload;
    },
    [PoolAccessTypeMutations.SET_IS_LOADING_POOL_ACCESS_TYPES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingPoolAccessTypesListArchived = payload;
    },
    [PoolAccessTypeMutations.SET_IS_LOADING_POOL_ACCESS_TYPE]: (state, payload: boolean) => {
        state.isLoadingPoolAccessType = payload;
    },
    [PoolAccessTypeMutations.SET_IS_CREATING_POOL_ACCESS_TYPE]: (state, payload: boolean) => {
        state.isCreatingPoolAccessType = payload;
    },
    [PoolAccessTypeMutations.SET_IS_UPDATING_POOL_ACCESS_TYPE]: (state, payload: boolean) => {
        state.isUpdatingPoolAccessType = payload;
    },
    [PoolAccessTypeMutations.SET_IS_DELETING_POOL_ACCESS_TYPE]: (state, payload: boolean) => {
        state.isDeletingPoolAccessType = payload;
    },
    [PoolAccessTypeMutations.SET_REQUEST_STATE_POOL_ACCESS_TYPE]: (state, payload: IRequestState) => {
        state.requestStatePoolAccessType = payload;
    },
    [PoolAccessTypeMutations.SET_IS_OPEN_SIDEBAR_POOL_ACCESS_TYPE]: (state, payload: boolean) => {
        state.isOpenSidebarPoolAccessType = payload;
    },
    [PoolAccessTypeMutations.SET_IS_ARCHIVING_POOL_ACCESS_TYPE]: (state, payload: boolean) => {
        state.isArchivingPoolAccessType = payload;
    },
}