import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";// RouterTabRoutes
import { RouterTabRoutes } from "vue-router-tab";
import Frame from "@/layouts/Frame.vue";

import "material-icons/iconfont/material-icons.css"; //Material Icons

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      // parent component must contain <router-tab>
      component: Frame,
      // routes that serve as tab contents
      children: [
        // integrate RouterTabRoutes to support iframe tabs
        ...RouterTabRoutes,
        {
          path: "/",
          name: "home",
          component: () => import("@/views/Home.vue"),
          meta: {
            pageTitle: "Accueil",
            breadcrumb: [
              {
                text: "Accueil",
                active: false,
              },
            ],
            title: "Accueil",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Tableau de bord principal",
            key: "path",
            role: "admin",
            closable: true,
          },
        },
        {
          path: "/admin/admin-workspaces",
          name: "adminWorkspaces",
          component: () => import("@/views/account/workspace/Workspaces.vue"),
          meta: {
            pageTitle: "Environnements",
            breadcrumb: [
              {
                text: "Environnements",
                active: true,
              },
            ],
            title: "Workspaces",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Gestion des bases de données des clients",
            key: "path",
            closable: true,
            role: "admin",
            closeOnSwitchWorkspace: true,
          },
        },

        /* *** Gestion du compte *** */
        {
          path: "/account",
          name: "account",
          component: () => import("@/views/account/users/Account.vue"),
          meta: {
            pageTitle: "Mon compte",
            breadcrumb: [
              {
                text: "Mon compte",
                active: true,
              },
            ],
            title: "Mon compte",
            icon: "icon-user",
            tips: "Gestion de mon compte ORISIS",
            key: "path",
            role: "admin",
            closable: true,
          },
        },

        /* *** Gestion des Users *** */
        {
          path: "/users",
          name: "users",
          component: () => import("@/views/directory/user/Users.vue"),
          meta: {
            pageTitle: "Utilisateurs",
            breadcrumb: [
              {
                text: "Utilisateurs",
                active: true,
              },
            ],
            title: "Utilisateurs",
            icon: "icon-users",
            tips: "Liste des utilisateurs",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/new-user",
          name: "new-user",
          component: () => import("@/views/directory/user/EditUser.vue"),
          props: true,
          meta: {
            pageTitle: "Nouvel utilisateur",
            breadcrumb: [
              {
                text: "Utilisateurs",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouvel utilisateur",
            icon: "icon-home",
            tips: "Créer un nouveau utilisateur",
            key: "path",
            closable: true,
            role: "admin",
            closeOnSwitchWorkspace: true,
          },
        },
        {
          path: "/edit-user/:id",
          name: "edit-user",
          component: () => import("@/views/directory/user/EditUser.vue"),
          props: true,
          meta: {
            pageTitle: "Fiche utilisateur",
            breadcrumb: [
              {
                text: "Utilisateurs",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un utilisateur",
            icon: "icon-home",
            tips: "Editer un utilisateur",
            key: "path",
            closable: true,
            role: "admin",
            closeOnSwitchWorkspace: true,
          },
        },
        /* *** Gestion des Actions courantes *** */
        {
          path: "/commonTask",
          name: "commonTask",
          component: () => import("@/views/commonAction/commonTask/CommonTask.vue"),
          meta: {
            pageTitle: "Tâches courantes",
            breadcrumb: [
              {
                text: "Tâches",
                active: true,
              },
            ],
            title: "Tâches courantes",
            icon: "icon-users",
            tips: "Liste des tâches courantes",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/commonStep",
          name: "commonStep",
          component: () => import("@/views/commonAction/commonStep/CommonStep.vue"),
          meta: {
            pageTitle: "Étapes courantes",
            breadcrumb: [
              {
                text: "Étapes",
                active: true,
              },
            ],
            title: "Étapes courantes",
            icon: "icon-users",
            tips: "Liste des étapes courantes",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        /* *** Gestion des Collaborateurs *** */
        {
          path: "/jobs",
          name: "jobs",
          component: () =>
            import("@/views/parameters/jobs/Jobs.vue"),
          meta: {
            pageTitle: "Métiers",
            breadcrumb: [
              {
                text: "Métiers",
                active: true,
              },
            ],
            title: "Métiers",
            icon: "icon-users",
            tips: "Liste des métiers",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/collaborators",
          name: "collaborators",
          component: () =>
            import("@/views/directory/collaborator/Collaborators.vue"),
          meta: {
            pageTitle: "Collaborateurs",
            breadcrumb: [
              {
                text: "Collaborateurs",
                active: true,
              },
            ],
            title: "Collaborateurs",
            icon: "icon-users",
            tips: "Liste des collaborateurs",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/new-collaborator",
          name: "new-collaborator",
          component: () =>
            import("@/views/directory/collaborator/EditCollaborator.vue"),
          props: true,
          meta: {
            pageTitle: "Nouveau collaborateur",
            breadcrumb: [
              {
                text: "Collaborateurs",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouveau collaborateur",
            icon: "icon-home",
            tips: "Créer un nouveau collaborateur",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            role: "admin",
          },
        },
        {
          path: "/edit-collaborator/:id",
          name: "edit-collaborator",
          component: () =>
            import("@/views/directory/collaborator/EditCollaborator.vue"),
          props: true,
          meta: {
            pageTitle: "Fiche collaborateur",
            breadcrumb: [
              {
                text: "Collaborateurs",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un collaborateur",
            icon: "icon-home",
            tips: "Editer un collaborateur",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            role: "admin",
          },
        },

        /* *** Gestion des Bassins *** */
        {
          path: "/pools",
          name: "pools",
          component: () => import("@/views/pool/Pools.vue"),
          props: true,
          meta: {
            pageTitle: "Bassins",
            breadcrumb: [
              {
                text: "Bassins",
                active: true,
              },
            ],
            title: "Bassins",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des bassins",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/edit-pool/:id",
          name: "edit-pool",
          component: () => import("@/views/pool/EditPool.vue"),
          meta: {
            pageTitle: "Editer un bassin",
            breadcrumb: [
              {
                text: "Bassins",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un bassin",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Editer un bassin",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/new-pool",
          name: "new-pool",
          component: () => import("@/views/pool/EditPool.vue"),
          meta: {
            pageTitle: "Nouveau bassin",
            breadcrumb: [
              {
                text: "Bassins",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouveau bassin",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Créer un nouveau bassin",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        /* *** Gestion des Interventions *** */
        {
          path: "/interventions",
          name: "interventions",
          component: () => import("@/views/intervention/Interventions.vue"),
          meta: {
            pageTitle: "Interventions",
            breadcrumb: [
              {
                text: "Interventions",
                active: true,
              },
            ],
            title: "Interventions",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des interventions",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/edit-intervention/:id",
          name: "edit-intervention",
          component: () => import("@/views/intervention/EditIntervention.vue"),
          meta: {
            pageTitle: "Editer une intervention",
            breadcrumb: [
              {
                text: "Intervention",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer une intervention",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Editer une intervention",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/new-intervention",
          name: "new-intervention",
          component: () => import("@/views/intervention/EditIntervention.vue"),
          meta: {
            pageTitle: "Nouvelle intervention",
            breadcrumb: [
              {
                text: "Intervention",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouvelle intervention",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Créer une nouvelle intervention",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        /* *** Gestion des Appareils *** */
        {
          path: "/device",
          name: "device",
          component: () => import("@/views/device/Device.vue"),
          meta: {
            pageTitle: "Liste des appareils",
            breadcrumb: [
              {
                text: "Appareil",
                active: true,
              },
            ],
            title: "Liste des appareils",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des appareils",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/new-device",
          name: "new-device",
          component: () => import("@/views/device/EditDevice.vue"),
          meta: {
            pageTitle: "Nouvel appareil",
            breadcrumb: [
              {
                text: "Apprareil",
                active: true,
              },
              {
                text: "Nouveau",
                active: true,
              },
            ],
            title: "Nouvel appareil",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Créer un nouvel apprareil",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/edit-device",
          name: "edit-device",
          component: () => import("@/views/device/EditDevice.vue"),
          meta: {
            pageTitle: "Editer un appareil",
            breadcrumb: [
              {
                text: "Apprareil",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un appareil",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Editer un apprareil",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/brand",
          name: "brand",
          component: () => import("@/views/brand/Brand.vue"),
          meta: {
            pageTitle: "Liste des marques",
            breadcrumb: [
              {
                text: "Marque",
                active: true,
              },
            ],
            title: "Liste des marques",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des marques",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        /* *** Gestion des produits *** */
        {
          path: "/produits",
          name: "products",
          component: () => import("@/views/products/Products.vue"),
          meta: {
            pageTitle: "Produits",
            breadcrumb: [
              {
                text: "Produits",
                active: true,
              },
            ],
            title: "Produits",
            icon: "water_drop",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des produits",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        /* *** Gestion des Types *** */
        {
          path: "/types/interventions",
          name: "types-interventions",
          component: () => import("@/views/types/interventionsTypes/InterventionsTypes"),
          meta: {
            pageTitle: "Types d'interventions",
            breadcrumb: [
              {
                text: "Types d'interventions",
                active: true,
              },
            ],
            title: "Types d'interventions",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des types d'interventions",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/types/devices",
          name: "types-devices",
          component: () => import("@/views/types/deviceType/DeviceTypes"),
          meta: {
            pageTitle: "Types d'appareils",
            breadcrumb: [
              {
                text: "Types d'appareils",
                active: true,
              },
            ],
            title: "Types d'appareils",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des types d'appareils",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/types/taches",
          name: "types-tâches",
          // component: () => import("@/views/directory/pool/Pools.vue"),
          meta: {
            pageTitle: "Types de tâches",
            breadcrumb: [
              {
                text: "Types de tâches",
                active: true,
              },
            ],
            title: "Types de tâches",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des types de tâches",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/types/contratsDeMaintenances",
          name: "types-contrats-maintenances",
          component: () => import("@/views/types/contractMaintenanceType/ContractMaintenanceTypes.vue"),
          meta: {
            pageTitle: "Types de contrats de maintenances",
            breadcrumb: [
              {
                text: "Types de contrats de maintenances",
                active: true,
              },
            ],
            title: "Types de contrats de maintenances",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des types de contrats de maintenance",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/types/bassins",
          name: "types-bassins",
          component: () => import("@/views/types/poolsTypes/PoolsTypes.vue"),
          meta: {
            pageTitle: "Types de bassins",
            breadcrumb: [
              {
                text: "Types de bassins",
                active: true,
              },
            ],
            title: "Types de bassins",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des types de bassins",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/types/revetements",
          name: "types-revêtements",
          component: () => import("@/views/types/coatingsTypes/CoatingsTypes.vue"),
          meta: {
            pageTitle: "Types de revêtements",
            breadcrumb: [
              {
                text: "Types de revêtements",
                active: true,
              },
            ],
            title: "Types de revêtement",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des types de revêtements",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/type/traitements",
          name: "types-traitements",
          component: () => import("@/views/types/waterTreatmentsTypes/WaterTreatmentsTypes.vue"),
          meta: {
            pageTitle: "Types de traitements",
            breadcrumb: [
              {
                text: "Types de traitements",
                active: true,
              },
            ],
            title: "Types de traitements",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des types de traitements",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/type/contratsProfessionnels",
          name: "type-contrats-professionnels",
          component: () => import("@/views/types/professionalContractsTypes/ProfessionalContractsTypes.vue"),
          meta: {
            pageTitle: "Types de contrats",
            breadcrumb: [
              {
                text: "Types de contrats",
                active: true,
              },
            ],
            title: "Types de contrats",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des Types de contrats",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/type/acces",
          name: "type-acces",
          component: () => import("@/views/types/poolAccessTypes/PoolAccessTypes.vue"),
          meta: {
            pageTitle: "Types d'accès au bassin",
            breadcrumb: [
              {
                text: "Types d'accès au bassin",
                active: true,
              },
            ],
            title: "Types d'accès au bassin",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des types daccès au bassin",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/type/methodeAcces",
          name: "type-methode-acces",
          component: () => import("@/views/types/accessMethodTypes/AccessMethodTypes.vue"),
          meta: {
            pageTitle: "Méthode d'accès au bassin",
            breadcrumb: [
              {
                text: "Méthode d'accès au bassin",
                active: true,
              },
            ],
            title: "Méthodes d'accès au bassin",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des méthodes daccès au bassin",
            key: "path",
            closable: true,
            role: "admin",
          },
        },

        /* *** Gestion des Clients *** */
        {
          path: "/directory/customers",
          name: "customers",
          component: () => import("@/views/directory/customer/Customers.vue"),
          meta: {
            pageTitle: "Clients",
            breadcrumb: [
              {
                text: "Clients",
                active: true,
              },
            ],
            title: "Clients",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des clients",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/directory/new-customer",
          name: "new-customer",
          component: () =>
            import("@/views/directory/customer/EditCustomer.vue"),
          props: true,
          meta: {
            pageTitle: "Nouveau client",
            breadcrumb: [
              {
                text: "Clients",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouveau client",
            icon: "icon-home",
            tips: "Créer un nouveau client",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            role: "admin",
          },
        },
        {
          path: "/directory/edit-customer/:id",
          name: "edit-customer",
          component: () =>
            import("@/views/directory/customer/EditCustomer.vue"),
          props: true,
          meta: {
            pageTitle: "Fiche client",
            breadcrumb: [
              {
                text: "Clients",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un client",
            icon: "icon-home",
            tips: "Editer un client",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            role: "admin",
          },
        },

        /* *** Gestion des Paramètres Divers *** */
        {
          path: "/parameters/contracts",
          name: "contracts",
          component: () => import("@/views/parameters/contracts/Contracts.vue"),
          meta: {
            pageTitle: "Contrats",
            breadcrumb: [
              {
                text: "Contrats",
                active: true,
              },
            ],
            title: "Contrats",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des contrats",
            key: "path",
            closable: true,
            role: "admin",
          },
        },
        {
          path: "/parameters/jobs",
          name: "jobs",
          component: () => import("@/views/parameters/jobs/Jobs.vue"),
          meta: {
            pageTitle: "Métiers",
            breadcrumb: [
              {
                text: "Métiers",
                active: true,
              },
            ],
            title: "Métiers",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des métiers",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            role: "admin",
          },
        },
      ],
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/authentification/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/workspace",
      name: "worspace",
      component: () => import("@/views/authentification/Workspace.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/forgot-password",
      name: "forgotPassword",
      component: () => import("@/views/authentification/ForgotPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/change-password/:origine",
      name: "changePassword",
      component: () => import("@/views/authentification/ChangePassword.vue"),
      props: true,
      meta: {
        layout: "full",
      },
    },
    {
      path: "/reset-password/:userEmail/:userCode*",
      name: "resetPassword",
      component: () => import("@/views/authentification/ResetPassword.vue"),
      props: true,
      meta: {
        layout: "full",
      },
    },
    {
      path: "/first-login/:userCode*",
      name: "firstLogin",
      component: () => import("@/views/authentification/FirstLogin.vue"),
      props: true,
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-401",
      name: "error401",
      component: () => import("@/views/error/Error401.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "login",
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const storeDocument = store.state;

  const checkToken = () => {
    if (
      localStorage.getItem("userToken") == null ||
      localStorage.getItem("userToken") == "null" ||
      localStorage.getItem("userToken") == undefined ||
      localStorage.getItem("userIsLoggedIn") == false ||
      localStorage.getItem("userIsLoggedIn") == "false" ||
      localStorage.getItem("userIsLoggedIn") == undefined
    ) {
      return false;
    } else {
      return true;
    }
  };

  // Liste des URL disponibles sans authentification
  const publicPagesName = [
    "login",
    "register",
    "firstLogin",
    "forgotPassword",
    "resetPassword",
    "firstLogin",
    "error401",
    "error404",
  ];
  const authRequired = !publicPagesName.includes(to.name);
  if (authRequired) {
    if (checkToken()) {
      if (store.state.login.userInfo) {
        let workspaceSelectedId = 0;

        if (
          localStorage.getItem("workspaceSelected") &&
          localStorage.getItem("workspaceSelected") !== null
        ) {
          workspaceSelectedId = JSON.parse(
            localStorage.getItem("workspaceSelected")
          ).id;
        } else {
          workspaceSelectedId = store.state.login.userInfo.workspaces[0].id;
        }

        if (!from.name || from.name == null) {
          // || publicPagesName.includes(from.name)
          await store.dispatch("setIsGlobalLoading", true);
          await store
            .dispatch("setWorkspaceSelected", {
              workspaceIdSelected: workspaceSelectedId
            })
        }

        //Gestion des titre de tabs dynamiquement
        if (to.params && to.params.title) {
          to.meta.title = to.params.title;
          to.meta.tips = to.params.tips ? to.params.tips : to.params.title;
        } else if (to.params && to.params.name) {
          if (to.path.includes("new-")) {
            to.meta.title = "Nouveau " + to.params.name;
            to.meta.pageTitle = "Nouveau " + to.params.name;
            to.meta.tips = "Créer un nouveau " + to.params.name;
          } else {
            to.meta.title =
              to.params.name[0].toUpperCase() + to.params.name.slice(1);
            to.meta.pageTitle =
              to.params.name[0].toUpperCase() + to.params.name.slice(1);
            to.meta.tips = "Liste des " + to.params.name + "s";
          }
        }

        // Gestion de l'interdiction d'accès au pages administrateur
        if (
          to.meta?.role &&
          to.meta?.role == "admin" &&
          !JSON.parse(localStorage.getItem("userInfo")).roles.includes("Admin")
        ) {
          return next("/error-401");
        } else {
          next();
        }
      } else {
        return next("/login");
      }
    } else {
      return next("/login");
    }
  } else {
    next();
  }
});

router.afterEach(async (to, from, next) => {
  if (!from.name || from.name == null) {
    setTimeout(async () => {
      await store.dispatch("setIsGlobalLoading", false);
    }, 200);
  }
});
export default router;
