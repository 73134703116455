import { IWorkspaceState } from "./states"
import { Module } from "vuex"
import { WorkspaceRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IWorkspaceState = {
    workspacesList: Array<WorkspaceRequestDto>(),
    workspacesListByUser: Array<WorkspaceRequestDto>(),
    workspace: <WorkspaceRequestDto>{},
    isLoadingWorkspacesList: false,
    isLoadingWorkspacesListByUser: false,
    isLoadingWorkspace: false,
    isCreatingWorkspace: false,
    isUpdatingWorkspace: false,
    isDeletingWorkspace: false,
    requestStateWorkspace: <IRequestState>{},
    workspaceSelected: <WorkspaceRequestDto>{}
}

export const WorkspaceModule: Module<IWorkspaceState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}