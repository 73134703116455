import { IPoolState } from "./states"
import { Module } from "vuex"
import { PoolResponseDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IPoolState = {
    poolsList : Array<PoolResponseDto>(),
    poolsListArchived : Array<PoolResponseDto>(),
    pool      : <PoolResponseDto>{},
    isLoadingPoolsList    : false,
    isLoadingPoolsListArchived    : false,
    isLoadingPool    : false,
    isCreatingPool   : false,
    isUpdatingPool   : false,
    isDeletingPool   : false,
    requestStatePool : <IRequestState>{},
    isOpenSidebarPool : false,
    isArchivingPool : false,
}

export const PoolModule: Module<IPoolState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}