import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { WorkspaceRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IWorkspaceState } from "./states";

export enum WorkspaceMutations {
    SET_WORKSPACES_LIST_BY_USER = "SET_WORKSPACES_LIST_BY_USER",
    SET_WORKSPACES_LIST = "SET_WORKSPACES_LIST",
    SET_WORKSPACE = "SET_WORKSPACE",
    SET_WORKSPACE_SELECTED = "SET_WORKSPACE_SELECTED",

    // COMMON
    SET_IS_LOADING_WORKSPACES_LIST_BY_USER = "SET_IS_LOADING_WORKSPACES_LIST_BY_USER",
    SET_IS_LOADING_WORKSPACES_LIST = "SET_IS_LOADING_WORKSPACES_LIST",
    SET_IS_LOADING_WORKSPACE = "SET_IS_LOADING_WORKSPACE",
    SET_IS_CREATING_WORKSPACE = "SET_IS_CREATING_WORKSPACE",
    SET_IS_UPDATING_WORKSPACE = "SET_IS_UPDATING_WORKSPACE",
    SET_IS_DELETING_WORKSPACE = "SET_IS_DELETING_WORKSPACE",
    SET_REQUEST_STATE_WORKSPACE = "SET_REQUEST_STATE_WORKSPACE"
}

export const mutations: MutationTree<IWorkspaceState> = {
    [WorkspaceMutations.SET_WORKSPACES_LIST_BY_USER]: (state, payload: WorkspaceRequestDto[]) => {
        state.workspacesListByUser = payload;
    },
    [WorkspaceMutations.SET_WORKSPACES_LIST]: (state, payload: WorkspaceRequestDto[]) => {
        state.workspacesList = payload;
    },
    [WorkspaceMutations.SET_WORKSPACE]: (state, payload: WorkspaceRequestDto) => {
        state.workspace = payload;
    },
    [WorkspaceMutations.SET_WORKSPACE_SELECTED]: (state, payload: WorkspaceRequestDto) => {
        state.workspaceSelected = payload;
        localStorage.setItem('workspaceSelected', JSON.stringify(payload))
    },

    //COMMON
    [WorkspaceMutations.SET_IS_LOADING_WORKSPACES_LIST]: (state, payload: boolean) => {
        state.isLoadingWorkspacesList = payload;
    },
    [WorkspaceMutations.SET_IS_LOADING_WORKSPACES_LIST_BY_USER]: (state, payload: boolean) => {
        state.isLoadingWorkspacesListByUser = payload;
    },
    [WorkspaceMutations.SET_IS_LOADING_WORKSPACE]: (state, payload: boolean) => {
        state.isLoadingWorkspace = payload;
    },
    [WorkspaceMutations.SET_IS_CREATING_WORKSPACE]: (state, payload: boolean) => {
        state.isCreatingWorkspace = payload;
    },
    [WorkspaceMutations.SET_IS_UPDATING_WORKSPACE]: (state, payload: boolean) => {
        state.isUpdatingWorkspace = payload;
    },
    [WorkspaceMutations.SET_IS_DELETING_WORKSPACE]: (state, payload: boolean) => {
        state.isDeletingWorkspace = payload;
    },
    [WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE]: (state, payload: IRequestState) => {
        state.requestStateWorkspace = payload;
    },
}