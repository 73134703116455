import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { WorkspaceRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../rootState'
import { IWorkspaceState } from './states'


export const getters: GetterTree<IWorkspaceState, RootState> = {
  workspacesList: (state): WorkspaceRequestDto[] => state.workspacesList,
  workspacesListByUser: (state): WorkspaceRequestDto[] => state.workspacesListByUser,
  workspace     : (state): WorkspaceRequestDto => state.workspace,
  workspaceSelected: (state): WorkspaceRequestDto => state.workspaceSelected,

  // COMMON
  isLoadingWorkspacesListByUser   : (state): boolean => state.isLoadingWorkspacesListByUser,
  isLoadingWorkspacesList   : (state): boolean => state.isLoadingWorkspacesList,
  isLoadingWorkspace   : (state): boolean => state.isLoadingWorkspace,
  isCreatingWorkspace  : (state): boolean => state.isCreatingWorkspace,
  isUpdatingWorkspace  : (state): boolean => state.isUpdatingWorkspace,
  isDeletingWorkspace  : (state): boolean => state.isDeletingWorkspace,
  requestStateWorkspace: (state): IRequestState => state.requestStateWorkspace
}
