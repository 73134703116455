import { IMissionTypeState } from "./states"
import { Module } from "vuex"
import { MissionTypeResponseDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IMissionTypeState = {
    missionTypesList : Array<MissionTypeResponseDto>(),
    missionTypesListArchived : Array<MissionTypeResponseDto>(),
    missionType      : <MissionTypeResponseDto>{},
    isLoadingMissionTypesList    : false,
    isLoadingMissionTypesListArchived    : false,
    isLoadingMissionType    : false,
    isCreatingMissionType   : false,
    isUpdatingMissionType   : false,
    isDeletingMissionType   : false,
    requestStateMissionType : <IRequestState>{},
    isOpenSidebarMissionType : false,
    isArchivingMissionType : false,
}

export const MissionTypeModule: Module<IMissionTypeState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}