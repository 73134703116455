import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { InterventionResponseDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IInterventionState } from "./states";

export enum InterventionMutations {
    SET_INTERVENTIONS_LIST = "SET_INTERVENTIONS_LIST",
    SET_INTERVENTIONS_LIST_ARCHIVED = "SET_INTERVENTIONS_LIST_ARCHIVED",
    SET_INTERVENTION = "SET_INTERVENTION",

    // COMMON
    SET_IS_LOADING_INTERVENTIONS_LIST= "SET_IS_LOADING_INTERVENTIONS_LIST",
    SET_IS_LOADING_INTERVENTIONS_LIST_ARCHIVED= "SET_IS_LOADING_INTERVENTIONS_LIST_ARCHIVED",
    
    SET_IS_LOADING_INTERVENTION= "SET_IS_LOADING_INTERVENTION",
    SET_IS_CREATING_INTERVENTION = "SET_IS_CREATING_INTERVENTION",
    SET_IS_UPDATING_INTERVENTION= "SET_IS_UPDATING_INTERVENTION",
    SET_IS_UPDATING_STEP_INTERVENTION= "SET_IS_UPDATING_STEP_INTERVENTION",
    SET_IS_DELETING_INTERVENTION = "SET_IS_DELETING_INTERVENTION",
    SET_REQUEST_STATE_INTERVENTION = "SET_REQUEST_STATE_INTERVENTION",
    SET_IS_OPEN_SIDEBAR_INTERVENTION = "SET_IS_OPEN_SIDEBAR_INTERVENTION",
    SET_IS_ARCHIVING_INTERVENTION = "SET_IS_ARCHIVING_INTERVENTION"
}

export const mutations: MutationTree<IInterventionState> = {
    [InterventionMutations.SET_INTERVENTIONS_LIST]: (state, payload: InterventionResponseDto[]) => {
        state.interventionsList = payload;
    },
    [InterventionMutations.SET_INTERVENTIONS_LIST_ARCHIVED]: (state, payload: InterventionResponseDto[]) => {
        state.interventionsListArchived = payload;
    },
    [InterventionMutations.SET_INTERVENTION]: (state, payload: InterventionResponseDto) => {
        state.intervention = payload;
    },


    //COMMON
    [InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST]: (state, payload: boolean) => {
        state.isLoadingInterventionsList = payload;
    },
    [InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingInterventionsListArchived = payload;
    },
    [InterventionMutations.SET_IS_LOADING_INTERVENTION]: (state, payload: boolean) => {
        state.isLoadingIntervention = payload;
    },
    [InterventionMutations.SET_IS_CREATING_INTERVENTION]: (state, payload: boolean) => {
        state.isCreatingIntervention = payload;
    },
    [InterventionMutations.SET_IS_UPDATING_INTERVENTION]: (state, payload: boolean) => {
        state.isUpdatingIntervention = payload;
    },
    [InterventionMutations.SET_IS_UPDATING_STEP_INTERVENTION]: (state, payload: boolean) => {
        state.isUpdatingStepIntervention = payload;
    },
    [InterventionMutations.SET_IS_DELETING_INTERVENTION]: (state, payload: boolean) => {
        state.isDeletingIntervention = payload;
    },
    [InterventionMutations.SET_REQUEST_STATE_INTERVENTION]: (state, payload: IRequestState) => {
        state.requestStateIntervention = payload;
    },
    [InterventionMutations.SET_IS_OPEN_SIDEBAR_INTERVENTION]: (state, payload: boolean) => {
        state.isOpenSidebarIntervention = payload;
    },
    [InterventionMutations.SET_IS_ARCHIVING_INTERVENTION]: (state, payload: boolean) => {
        state.isArchivingIntervention = payload;
    },
}