import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { JobRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IJobState } from "./states";

export enum JobMutations {
    SET_JOBS_LIST = "SET_JOBS_LIST",
    SET_JOBS_LIST_ARCHIVED = "SET_JOBS_LIST_ARCHIVED",
    SET_JOB = "SET_JOB",

    // COMMON
    SET_IS_LOADING_JOB_LIST = "SET_IS_LOADING_JOB_LIST",
    SET_IS_LOADING_JOB_LIST_ARCHIVED = "SET_IS_LOADING_JOB_LIST_ARCHIVED",
    SET_IS_LOADING_JOB = "SET_IS_LOADING_JOB",
    SET_IS_CREATING_JOB = "SET_IS_CREATING_JOB",
    SET_IS_UPDATING_JOB = "SET_IS_UPDATING_JOB",
    SET_IS_DELETING_JOB = "SET_IS_DELETING_JOB",
    SET_IS_ARCHIVING_JOB = "SET_IS_DELETING_JOB",
    SET_REQUEST_STATE_JOB = "SET_REQUEST_STATE_JOB",
    SET_IS_OPEN_SIDEBAR_JOB = "SET_IS_OPEN_SIDEBAR_JOB"
}

export const mutations: MutationTree<IJobState> = {
    [JobMutations.SET_JOBS_LIST_ARCHIVED]: (state, payload: JobRequestDto[]) => {
        state.jobsListArchived = payload;
    }, 
    [JobMutations.SET_JOBS_LIST]: (state, payload: JobRequestDto[]) => {
        state.jobsList = payload;
    },
    [JobMutations.SET_JOB]: (state, payload: JobRequestDto) => {
        state.job = payload;
    },


    //COMMON
    [JobMutations.SET_IS_LOADING_JOB_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingJobsListArchived = payload;
    },
    [JobMutations.SET_IS_LOADING_JOB_LIST]: (state, payload: boolean) => {
        state.isLoadingJobsList = payload;
    },
    [JobMutations.SET_IS_LOADING_JOB]: (state, payload: boolean) => {
        state.isLoadingJob = payload;
    },
    [JobMutations.SET_IS_CREATING_JOB]: (state, payload: boolean) => {
        state.isCreatingJob = payload;
    },
    [JobMutations.SET_IS_UPDATING_JOB]: (state, payload: boolean) => {
        state.isUpdatingJob = payload;
    },
    [JobMutations.SET_IS_DELETING_JOB]: (state, payload: boolean) => {
        state.isDeletingJob = payload;
    },
    [JobMutations.SET_IS_ARCHIVING_JOB]: (state, payload: boolean) => {
        state.isArchivingJob = payload;
    },
    [JobMutations.SET_REQUEST_STATE_JOB]: (state, payload: IRequestState) => {
        state.requestStateJob = payload;
    },
    [JobMutations.SET_IS_OPEN_SIDEBAR_JOB]: (state, payload: boolean) => {
        state.isOpenSidebarJob = payload;
    },
}