import { IProductState } from "./states";
import { Module } from "vuex";
import { ProductResponseDto } from "@/../Api";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const state: IProductState = {
  productsList: Array<ProductResponseDto>(),
  productsListArchived: Array<ProductResponseDto>(),
  product: <ProductResponseDto>{},
  isLoadingProductsList: false,
  isLoadingProductsListArchived: false,
  isLoadingProduct: false,
  isCreatingProduct: false,
  isUpdatingProduct: false,
  isDeletingProduct: false,
  requestStateProduct: <IRequestState>{},
  isOpenSidebarProduct: false,
  isArchivingProduct: false,
};

export const ProductModule: Module<IProductState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
