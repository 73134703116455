import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { MissionResponseDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IMissionState } from "./states";

export enum MissionMutations {
    SET_MISSIONS_LIST = "SET_MISSIONS_LIST",
    SET_COMMON_MISSIONS_LIST = "SET_COMMON_MISSIONS_LIST",
    SET_MISSIONS_LIST_ARCHIVED = "SET_MISSIONS_LIST_ARCHIVED",
    SET_COMMON_MISSIONS_LIST_ARCHIVED = "SET_COMMON_MISSIONS_LIST_ARCHIVED",
    SET_MISSION = "SET_MISSION",

    // COMMON
    SET_IS_LOADING_MISSIONS_LIST= "SET_IS_LOADING_MISSIONS_LIST",
    SET_IS_LOADING_COMMON_MISSIONS_LIST= "SET_IS_LOADING_COMMON_MISSIONS_LIST",
    SET_IS_LOADING_MISSIONS_LIST_ARCHIVED= "SET_IS_LOADING_MISSIONS_LIST_ARCHIVED",
    SET_IS_LOADING_COMMON_MISSIONS_LIST_ARCHIVED= "SET_IS_LOADING_COMMON_MISSIONS_LIST_ARCHIVED",
    
    SET_IS_LOADING_MISSION= "SET_IS_LOADING_MISSION",
    SET_IS_CREATING_MISSION = "SET_IS_CREATING_MISSION",
    SET_IS_UPDATING_MISSION= "SET_IS_UPDATING_MISSION",
    SET_IS_DELETING_MISSION = "SET_IS_DELETING_MISSION",
    SET_REQUEST_STATE_MISSION = "SET_REQUEST_STATE_MISSION",
    SET_REQUEST_STATE_COMMON_MISSION = "SET_REQUEST_STATE_COMMON_MISSION",
    SET_IS_OPEN_SIDEBAR_MISSION = "SET_IS_OPEN_SIDEBAR_MISSION",
    SET_IS_ARCHIVING_MISSION = "SET_IS_ARCHIVING_MISSION"
}

export const mutations: MutationTree<IMissionState> = {
    [MissionMutations.SET_MISSIONS_LIST]: (state, payload: MissionResponseDto[]) => {
        state.missionsList = payload;
    },
    [MissionMutations.SET_COMMON_MISSIONS_LIST]: (state, payload: MissionResponseDto[]) => {
        state.commonMissionsList = payload;
    },
    [MissionMutations.SET_MISSIONS_LIST_ARCHIVED]: (state, payload: MissionResponseDto[]) => {
        state.missionsListArchived = payload;
    },
    [MissionMutations.SET_COMMON_MISSIONS_LIST_ARCHIVED]: (state, payload: MissionResponseDto[]) => {
        state.commonMissionsListArchived = payload;
    },
    [MissionMutations.SET_MISSION]: (state, payload: MissionResponseDto) => {
        state.mission = payload;
    },


    //COMMON
    [MissionMutations.SET_IS_LOADING_MISSIONS_LIST]: (state, payload: boolean) => {
        state.isLoadingMissionsList = payload;
    },
    [MissionMutations.SET_IS_LOADING_COMMON_MISSIONS_LIST]: (state, payload: boolean) => {
        state.isLoadingCommonMissionsList = payload;
    },
    [MissionMutations.SET_IS_LOADING_MISSIONS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingMissionsListArchived = payload;
    },
    [MissionMutations.SET_IS_LOADING_COMMON_MISSIONS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingCommonMissionsListArchived = payload;
    },
    [MissionMutations.SET_IS_LOADING_MISSION]: (state, payload: boolean) => {
        state.isLoadingMission = payload;
    },
    [MissionMutations.SET_IS_CREATING_MISSION]: (state, payload: boolean) => {
        state.isCreatingMission = payload;
    },
    [MissionMutations.SET_IS_UPDATING_MISSION]: (state, payload: boolean) => {
        state.isUpdatingMission = payload;
    },
    [MissionMutations.SET_IS_DELETING_MISSION]: (state, payload: boolean) => {
        state.isDeletingMission = payload;
    },
    [MissionMutations.SET_REQUEST_STATE_MISSION]: (state, payload: IRequestState) => {
        state.requestStateMission = payload;
    },
    [MissionMutations.SET_REQUEST_STATE_COMMON_MISSION]: (state, payload: IRequestState) => {
        state.requestStateCommonMission = payload;
    },
    [MissionMutations.SET_IS_OPEN_SIDEBAR_MISSION]: (state, payload: boolean) => {
        state.isOpenSidebarMission = payload;
    },
    [MissionMutations.SET_IS_ARCHIVING_MISSION]: (state, payload: boolean) => {
        state.isArchivingMission = payload;
    },
}