import { ActionTree } from "vuex";
import RootState from "../../rootState";
import { FilterMutations } from "./mutations";
import { IFilterState } from "./states";
import { IFilters } from "./iFilters"

export const actions: ActionTree<IFilterState, RootState> = {
  setFilter({commit}, {name: name, filter: filter} : {name: string, filter: IFilters}): void {
    switch (name) {
      case 'interventionFilters':
        commit(FilterMutations.SET_INTERVENTION_FILTERS, {filter});
        localStorage.setItem('interventionFilters', JSON.stringify(filter));
        break;
    }
  }
};
