import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { BrandResponseDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../rootState'
import { IBrandState } from './states'


export const getters: GetterTree<IBrandState, RootState> = {
  brandsList: (state): BrandResponseDto[] => state.brandsList,
  brandsListArchived: (state): BrandResponseDto[] => state.brandsListArchived,
  brand     : (state): BrandResponseDto => state.brand,

  // COMMON
  isLoadingBrandsList   : (state): boolean => state.isLoadingBrandsList,
  isLoadingBrandsListArchived   : (state): boolean => state.isLoadingBrandsListArchived,
  isLoadingBrand   : (state): boolean => state.isLoadingBrand,
  isCreatingBrand  : (state): boolean => state.isCreatingBrand,
  isUpdatingBrand  : (state): boolean => state.isUpdatingBrand,
  isDeletingBrand  : (state): boolean => state.isDeletingBrand,
  requestStateBrand: (state): IRequestState => state.requestStateBrand,
  isOpenSidebarBrand : (state): boolean => state.isOpenSidebarBrand,
  isArchivingBrand : (state): boolean => state.isArchivingBrand,
}