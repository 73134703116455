import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { WaterTreatmentTypeResponseDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IWaterTreatmentTypeState } from "./states";

export enum WaterTreatmentTypeMutations {
    SET_WATER_TREATMENT_TYPES_LIST = "SET_WATER_TREATMENT_TYPES_LIST",
    SET_WATER_TREATMENT_TYPES_LIST_ARCHIVED = "SET_WATER_TREATMENT_TYPES_LIST_ARCHIVED",
    SET_WATER_TREATMENT_TYPE = "SET_WATER_TREATMENT_TYPE",

    // COMMON
    SET_IS_LOADING_WATER_TREATMENT_TYPES_LIST= "SET_IS_LOADING_WATER_TREATMENT_TYPES_LIST",
    SET_IS_LOADING_WATER_TREATMENT_TYPES_LIST_ARCHIVED= "SET_IS_LOADING_WATER_TREATMENT_TYPES_LIST_ARCHIVED",
    
    SET_IS_LOADING_WATER_TREATMENT_TYPE= "SET_IS_LOADING_WATER_TREATMENT_TYPE",
    SET_IS_CREATING_WATER_TREATMENT_TYPE = "SET_IS_CREATING_WATER_TREATMENT_TYPE",
    SET_IS_UPDATING_WATER_TREATMENT_TYPE= "SET_IS_UPDATING_WATER_TREATMENT_TYPE",
    SET_IS_DELETING_WATER_TREATMENT_TYPE = "SET_IS_DELETING_WATER_TREATMENT_TYPE",
    SET_REQUEST_STATE_WATER_TREATMENT_TYPE = "SET_REQUEST_STATE_WATER_TREATMENT_TYPE",
    SET_IS_OPEN_SIDEBAR_WATER_TREATMENT_TYPE = "SET_IS_OPEN_SIDEBAR_WATER_TREATMENT_TYPE",
    SET_IS_ARCHIVING_WATER_TREATMENT_TYPE = "SET_IS_ARCHIVING_WATER_TREATMENT_TYPE"
}

export const mutations: MutationTree<IWaterTreatmentTypeState> = {
    [WaterTreatmentTypeMutations.SET_WATER_TREATMENT_TYPES_LIST]: (state, payload: WaterTreatmentTypeResponseDto[]) => {
        state.waterTreatmentTypesList = payload;
    },
    [WaterTreatmentTypeMutations.SET_WATER_TREATMENT_TYPES_LIST_ARCHIVED]: (state, payload: WaterTreatmentTypeResponseDto[]) => {
        state.waterTreatmentTypesListArchived = payload;
    },
    [WaterTreatmentTypeMutations.SET_WATER_TREATMENT_TYPE]: (state, payload: WaterTreatmentTypeResponseDto) => {
        state.waterTreatmentType = payload;
    },


    //COMMON
    [WaterTreatmentTypeMutations.SET_IS_LOADING_WATER_TREATMENT_TYPES_LIST]: (state, payload: boolean) => {
        state.isLoadingWaterTreatmentTypesList = payload;
    },
    [WaterTreatmentTypeMutations.SET_IS_LOADING_WATER_TREATMENT_TYPES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingWaterTreatmentTypesListArchived = payload;
    },
    [WaterTreatmentTypeMutations.SET_IS_LOADING_WATER_TREATMENT_TYPE]: (state, payload: boolean) => {
        state.isLoadingWaterTreatmentType = payload;
    },
    [WaterTreatmentTypeMutations.SET_IS_CREATING_WATER_TREATMENT_TYPE]: (state, payload: boolean) => {
        state.isCreatingWaterTreatmentType = payload;
    },
    [WaterTreatmentTypeMutations.SET_IS_UPDATING_WATER_TREATMENT_TYPE]: (state, payload: boolean) => {
        state.isUpdatingWaterTreatmentType = payload;
    },
    [WaterTreatmentTypeMutations.SET_IS_DELETING_WATER_TREATMENT_TYPE]: (state, payload: boolean) => {
        state.isDeletingWaterTreatmentType = payload;
    },
    [WaterTreatmentTypeMutations.SET_REQUEST_STATE_WATER_TREATMENT_TYPE]: (state, payload: IRequestState) => {
        state.requestStateWaterTreatmentType = payload;
    },
    [WaterTreatmentTypeMutations.SET_IS_OPEN_SIDEBAR_WATER_TREATMENT_TYPE]: (state, payload: boolean) => {
        state.isOpenSidebarWaterTreatmentType = payload;
    },
    [WaterTreatmentTypeMutations.SET_IS_ARCHIVING_WATER_TREATMENT_TYPE]: (state, payload: boolean) => {
        state.isArchivingWaterTreatmentType = payload;
    },
}