import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { InterventionTypeResponseDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IInterventionTypeState } from "./states";

export enum InterventionTypeMutations {
    SET_INTERVENTION_TYPES_LIST = "SET_INTERVENTION_TYPES_LIST",
    SET_INTERVENTION_TYPES_LIST_ARCHIVED = "SET_INTERVENTION_TYPES_LIST_ARCHIVED",
    SET_INTERVENTION_TYPE = "SET_INTERVENTION_TYPE",

    // COMMON
    SET_IS_LOADING_INTERVENTION_TYPES_LIST= "SET_IS_LOADING_INTERVENTION_TYPES_LIST",
    SET_IS_LOADING_INTERVENTION_TYPES_LIST_ARCHIVED= "SET_IS_LOADING_INTERVENTION_TYPES_LIST_ARCHIVED",
    
    SET_IS_LOADING_INTERVENTION_TYPE= "SET_IS_LOADING_INTERVENTION_TYPE",
    SET_IS_CREATING_INTERVENTION_TYPE = "SET_IS_CREATING_INTERVENTION_TYPE",
    SET_IS_UPDATING_INTERVENTION_TYPE= "SET_IS_UPDATING_INTERVENTION_TYPE",
    SET_IS_DELETING_INTERVENTION_TYPE = "SET_IS_DELETING_INTERVENTION_TYPE",
    SET_REQUEST_STATE_INTERVENTION_TYPE = "SET_REQUEST_STATE_INTERVENTION_TYPE",
    SET_IS_OPEN_SIDEBAR_INTERVENTION_TYPE = "SET_IS_OPEN_SIDEBAR_INTERVENTION_TYPE",
    SET_IS_ARCHIVING_INTERVENTION_TYPE = "SET_IS_ARCHIVING_INTERVENTION_TYPE"
}

export const mutations: MutationTree<IInterventionTypeState> = {
    [InterventionTypeMutations.SET_INTERVENTION_TYPES_LIST]: (state, payload: InterventionTypeResponseDto[]) => {
        state.interventionTypesList = payload;
    },
    [InterventionTypeMutations.SET_INTERVENTION_TYPES_LIST_ARCHIVED]: (state, payload: InterventionTypeResponseDto[]) => {
        state.interventionTypesListArchived = payload;
    },
    [InterventionTypeMutations.SET_INTERVENTION_TYPE]: (state, payload: InterventionTypeResponseDto) => {
        state.interventionType = payload;
    },


    //COMMON
    [InterventionTypeMutations.SET_IS_LOADING_INTERVENTION_TYPES_LIST]: (state, payload: boolean) => {
        state.isLoadingInterventionTypesList = payload;
    },
    [InterventionTypeMutations.SET_IS_LOADING_INTERVENTION_TYPES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingInterventionTypesListArchived = payload;
    },
    [InterventionTypeMutations.SET_IS_LOADING_INTERVENTION_TYPE]: (state, payload: boolean) => {
        state.isLoadingInterventionType = payload;
    },
    [InterventionTypeMutations.SET_IS_CREATING_INTERVENTION_TYPE]: (state, payload: boolean) => {
        state.isCreatingInterventionType = payload;
    },
    [InterventionTypeMutations.SET_IS_UPDATING_INTERVENTION_TYPE]: (state, payload: boolean) => {
        state.isUpdatingInterventionType = payload;
    },
    [InterventionTypeMutations.SET_IS_DELETING_INTERVENTION_TYPE]: (state, payload: boolean) => {
        state.isDeletingInterventionType = payload;
    },
    [InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE]: (state, payload: IRequestState) => {
        state.requestStateInterventionType = payload;
    },
    [InterventionTypeMutations.SET_IS_OPEN_SIDEBAR_INTERVENTION_TYPE]: (state, payload: boolean) => {
        state.isOpenSidebarInterventionType = payload;
    },
    [InterventionTypeMutations.SET_IS_ARCHIVING_INTERVENTION_TYPE]: (state, payload: boolean) => {
        state.isArchivingInterventionType = payload;
    },
}