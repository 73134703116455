import { ICoatingTypeState } from "./states"
import { Module } from "vuex"
import { CoatingTypeResponseDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:ICoatingTypeState = {
    coatingTypesList : Array<CoatingTypeResponseDto>(),
    coatingTypesListArchived : Array<CoatingTypeResponseDto>(),
    coatingType      : <CoatingTypeResponseDto>{},
    isLoadingCoatingTypesList    : false,
    isLoadingCoatingTypesListArchived    : false,
    isLoadingCoatingType    : false,
    isCreatingCoatingType   : false,
    isUpdatingCoatingType   : false,
    isDeletingCoatingType   : false,
    requestStateCoatingType : <IRequestState>{},
    isOpenSidebarCoatingType : false,
    isArchivingCoatingType : false,
}

export const CoatingTypeModule: Module<ICoatingTypeState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}