import { CollaboratorRequestDto } from '@/../Api'

import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../rootState'
import { CollaboratorMutations } from './mutations'
import { ICollaboratorState } from './states'

export const actions: ActionTree<ICollaboratorState, RootState> = {
  /**
   * It fetches the list of collaborators from the API and stores it in the state.
   */
  async fetchCollaboratorsList ({ commit, rootGetters }): Promise<void> {
    commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATORS_LIST, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Directory/Collaborator/GetCollaborators',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
        }
      )
      .then(res => {
        commit(CollaboratorMutations.SET_COLLABORATORS_LIST, res.data)
        commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res)
        commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATORS_LIST, false)
      })
      .catch(err => {
        console.error(err)
        commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err)
        commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATORS_LIST, false)
      })
  },
  async fetchCollaboratorsListArchived ({ commit, rootGetters }): Promise<void> {
    commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATORS_LIST_ARCHIVED, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Directory/Collaborator/GetCollaboratorsArchived',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
        }
      )
      .then(res => {
        commit(CollaboratorMutations.SET_COLLABORATORS_LIST_ARCHIVED, res.data)
        commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res)
        commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATORS_LIST_ARCHIVED, false)
      })
      .catch(err => {
        console.error(err)
        commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err)
        commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATORS_LIST_ARCHIVED, false)
      })
  },

  /**
   * Fetch a collaborator from the API
   * @param  - collaboratorId: The id of the collaborator you want to fetch.
   * @param {string} collaboratorId - The id of the collaborator you want to fetch.
   */
  async fetchCollaborator (
    { commit, rootGetters },  
    collaboratorId: string
  ): Promise<CollaboratorRequestDto> {
    return new Promise((resolve, reject) => {
        commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATOR, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS +
              'Directory/Collaborator/GetCollaboratorById',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                collaboratorId: collaboratorId
              }
            }
          )
          .then(res => {
            commit(CollaboratorMutations.SET_COLLABORATOR, res.data)
            commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res)
            commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATOR, false)
            resolve(res.data)
          })
          .catch(err => {
            console.error(err)
            commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err)
            commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATOR, false)
            reject(err)
          })
    })
  },

  async fetchCollaboratorArchived (
    { commit, rootGetters },
    collaboratorId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
        commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATOR, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS + 'Directory/Collaborator/GetCollaboratorArchivedById',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                collaboratorId: collaboratorId
              }
            }
          )
          .then(res => {
            commit(CollaboratorMutations.SET_COLLABORATOR, res.data)
            commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res)
            commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATOR, false)
            resolve(res.data)
          })
          .catch(err => {
            console.error(err)
            commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err)
            commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATOR, false)
            reject(err)
          })
    })
  },


  /**
   * Create a collaborator and add it to the collaborators list
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {CollaboratorRequestDto} collaborator - The collaborator object that will be sent to the API.
   */
  async createCollaborator (
    { commit, rootGetters },
    collaborator: CollaboratorRequestDto
  ): Promise<void> {
    commit(CollaboratorMutations.SET_IS_CREATING_COLLABORATOR, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS +
          'Directory/Collaborator/CreateCollaborator',
        collaborator,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.collaboratorsList.unshift(res.data.data)
        commit(
          CollaboratorMutations.SET_COLLABORATORS_LIST,
          rootGetters.collaboratorsList
        )
        commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res)
        commit(CollaboratorMutations.SET_IS_CREATING_COLLABORATOR, false)
      })
      .catch(err => {
        console.error(err)
        commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err)
        commit(CollaboratorMutations.SET_IS_CREATING_COLLABORATOR, false)
      })
  },

  /**
   * Update a collaborator
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {CollaboratorRequestDto} collaborator - The collaborator object that will be updated.
   */
  async updateCollaborator (
    { commit, rootGetters },
    collaborator: CollaboratorRequestDto
  ): Promise<void> {
    commit(CollaboratorMutations.SET_IS_UPDATING_COLLABORATOR, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS +
          'Directory/Collaborator/UpdateCollaborator',
        {...collaborator},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.collaboratorsList.splice(
          rootGetters.collaboratorsList.findIndex(
            elem => elem.id == collaborator.id
          ),
          1,
          collaborator
        )
        commit(
          CollaboratorMutations.SET_COLLABORATORS_LIST,
          rootGetters.collaboratorsList
        )

        commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res)
        commit(CollaboratorMutations.SET_IS_UPDATING_COLLABORATOR, false)
      })
      .catch(err => {
        console.error(err)
        commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err)
        commit(CollaboratorMutations.SET_IS_UPDATING_COLLABORATOR, false)
      })
  },

  /**
   * Delete a collaborator from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} collaboratorId - The id of the collaborator to delete.
   */
  async deleteCollaborator (
    { commit, rootGetters },
    collaboratorId: string
  ): Promise<void> {
    commit(CollaboratorMutations.SET_IS_DELETING_COLLABORATOR, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Directory/Collaborator/Delete',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          params: {
            collaboratorId: collaboratorId
          }
        }
      )
      .then(res => {
        rootGetters.collaboratorsListArchived.splice(
          rootGetters.collaboratorsListArchived.findIndex(elem => elem.id == collaboratorId),
          1
        )

        commit(
          CollaboratorMutations.SET_COLLABORATORS_LIST,
          rootGetters.collaboratorsList
        )
        commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res)
        commit(CollaboratorMutations.SET_IS_DELETING_COLLABORATOR, false)
      })
      .catch(err => {
        console.error(err)
        commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err)
        commit(CollaboratorMutations.SET_IS_DELETING_COLLABORATOR, false)
      })
  },

  /**
   * Delete a collaborator from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} collaboratorId - The id of the collaborator to delete.
   */
   async archiveCollaborator (
    { commit, rootGetters },
    collaboratorId: string
  ): Promise<void> {
    commit(CollaboratorMutations.SET_IS_ARCHIVING_COLLABORATOR, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Directory/Collaborator/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[collaboratorId]
        }
      )
      .then(res => {
        rootGetters.collaboratorsListArchived.unshift(rootGetters.collaboratorsList.find(elem => elem.id == collaboratorId ))
        rootGetters.collaboratorsList.splice(
          rootGetters.collaboratorsList.findIndex(
            elem => elem.id == collaboratorId
          ),
          1
        )
        commit(
          CollaboratorMutations.SET_COLLABORATORS_LIST,
          rootGetters.collaboratorsList
        )
        commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res)
        commit(CollaboratorMutations.SET_IS_ARCHIVING_COLLABORATOR, false)
      })
      .catch(err => {
        console.error(err)
        commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err)
        commit(CollaboratorMutations.SET_IS_ARCHIVING_COLLABORATOR, false)
      })
   },

   async restoreCollaborator (
    { commit, rootGetters },
    collaboratorId: string
  ): Promise<void> {
    commit(CollaboratorMutations.SET_IS_UPDATING_COLLABORATOR, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Directory/Collaborator/Restore',
        [collaboratorId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.collaboratorsList.unshift(rootGetters.collaboratorsListArchived.find(elem => elem.id == collaboratorId ))
        rootGetters.collaboratorsListArchived.splice(
          rootGetters.collaboratorsListArchived.findIndex(
            elem => elem.id == collaboratorId
          ),
          1
        )
        commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res)
        commit(CollaboratorMutations.SET_IS_UPDATING_COLLABORATOR, false)
      })
      .catch(err => {
        console.error(err)
        commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err)
        commit(CollaboratorMutations.SET_IS_UPDATING_COLLABORATOR, false)
      })
   }
  
}
