import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ContractTypeResponseDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IContractMaintenanceTypeState } from "./states";

export enum ContractMaintenanceTypeMutations {
    SET_CONTRACT_MAINTENANCE_TYPES_LIST = "SET_CONTRACT_MAINTENANCE_TYPES_LIST",
    SET_CONTRACT_MAINTENANCE_TYPES_LIST_ARCHIVED = "SET_CONTRACT_MAINTENANCE_TYPES_LIST_ARCHIVED",
    SET_CONTRACT_MAINTENANCE_TYPE = "SET_CONTRACT_MAINTENANCE_TYPE",

    // COMMON
    SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPES_LIST= "SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPES_LIST",
    SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPES_LIST_ARCHIVED= "SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPES_LIST_ARCHIVED",
    
    SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPE= "SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPE",
    SET_IS_CREATING_CONTRACT_MAINTENANCE_TYPE = "SET_IS_CREATING_CONTRACT_MAINTENANCE_TYPE",
    SET_IS_UPDATING_CONTRACT_MAINTENANCE_TYPE= "SET_IS_UPDATING_CONTRACT_MAINTENANCE_TYPE",
    SET_IS_DELETING_CONTRACT_MAINTENANCE_TYPE = "SET_IS_DELETING_CONTRACT_MAINTENANCE_TYPE",
    SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE = "SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE",
    SET_IS_OPEN_SIDEBAR_CONTRACT_MAINTENANCE_TYPE = "SET_IS_OPEN_SIDEBAR_CONTRACT_MAINTENANCE_TYPE",
    SET_IS_ARCHIVING_CONTRACT_MAINTENANCE_TYPE = "SET_IS_ARCHIVING_CONTRACT_MAINTENANCE_TYPE"
}

export const mutations: MutationTree<IContractMaintenanceTypeState> = {
    [ContractMaintenanceTypeMutations.SET_CONTRACT_MAINTENANCE_TYPES_LIST]: (state, payload: ContractTypeResponseDto[]) => {
        state.contractMaintenanceTypesList = payload;
    },
    [ContractMaintenanceTypeMutations.SET_CONTRACT_MAINTENANCE_TYPES_LIST_ARCHIVED]: (state, payload: ContractTypeResponseDto[]) => {
        state.contractMaintenanceTypesListArchived = payload;
    },
    [ContractMaintenanceTypeMutations.SET_CONTRACT_MAINTENANCE_TYPE]: (state, payload: ContractTypeResponseDto) => {
        state.contractMaintenanceType = payload;
    },


    //COMMON
    [ContractMaintenanceTypeMutations.SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPES_LIST]: (state, payload: boolean) => {
        state.isLoadingContractMaintenanceTypesList = payload;
    },
    [ContractMaintenanceTypeMutations.SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingContractMaintenanceTypesListArchived = payload;
    },
    [ContractMaintenanceTypeMutations.SET_IS_LOADING_CONTRACT_MAINTENANCE_TYPE]: (state, payload: boolean) => {
        state.isLoadingContractMaintenanceType = payload;
    },
    [ContractMaintenanceTypeMutations.SET_IS_CREATING_CONTRACT_MAINTENANCE_TYPE]: (state, payload: boolean) => {
        state.isCreatingContractMaintenanceType = payload;
    },
    [ContractMaintenanceTypeMutations.SET_IS_UPDATING_CONTRACT_MAINTENANCE_TYPE]: (state, payload: boolean) => {
        state.isUpdatingContractMaintenanceType = payload;
    },
    [ContractMaintenanceTypeMutations.SET_IS_DELETING_CONTRACT_MAINTENANCE_TYPE]: (state, payload: boolean) => {
        state.isDeletingContractMaintenanceType = payload;
    },
    [ContractMaintenanceTypeMutations.SET_REQUEST_STATE_CONTRACT_MAINTENANCE_TYPE]: (state, payload: IRequestState) => {
        state.requestStateContractMaintenanceType = payload;
    },
    [ContractMaintenanceTypeMutations.SET_IS_OPEN_SIDEBAR_CONTRACT_MAINTENANCE_TYPE]: (state, payload: boolean) => {
        state.isOpenSidebarContractMaintenanceType = payload;
    },
    [ContractMaintenanceTypeMutations.SET_IS_ARCHIVING_CONTRACT_MAINTENANCE_TYPE]: (state, payload: boolean) => {
        state.isArchivingContractMaintenanceType = payload;
    },
}