import { InterventionTypeResponseDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../../rootState'
import { InterventionTypeMutations } from './mutations'
import { IInterventionTypeState } from './states'

export const actions: ActionTree<IInterventionTypeState, RootState> = {
  /**
   * It fetches the list of interventionTypes from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchInterventionTypesList ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(InterventionTypeMutations.SET_IS_LOADING_INTERVENTION_TYPES_LIST, true)
      commit(InterventionTypeMutations.SET_IS_LOADING_INTERVENTION_TYPES_LIST_ARCHIVED, false)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Management/InterventionType/GetInterventionTypes',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
          }
        )
        .then(res => {
          commit(InterventionTypeMutations.SET_INTERVENTION_TYPES_LIST, res.data)
          commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, res)
          commit(InterventionTypeMutations.SET_IS_LOADING_INTERVENTION_TYPES_LIST, false)
          resolve(res.data)

        })
        .catch(err => {
          console.error(err)
          commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, err)
          commit(InterventionTypeMutations.SET_IS_LOADING_INTERVENTION_TYPES_LIST, false)
          reject(err)
        })
    })
  },

  async fetchInterventionTypesListArchived ({ commit, rootGetters }): Promise<void> {
    commit(InterventionTypeMutations.SET_IS_LOADING_INTERVENTION_TYPES_LIST_ARCHIVED, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Management/InterventionType/GetInterventionTypeArchived',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
        }
      )
      .then(res => {
        commit(InterventionTypeMutations.SET_INTERVENTION_TYPES_LIST_ARCHIVED, res.data)
        commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, res)
        commit(InterventionTypeMutations.SET_IS_LOADING_INTERVENTION_TYPES_LIST_ARCHIVED, false)
      })
      .catch(err => {
        console.error(err)
        commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, err)
        commit(InterventionTypeMutations.SET_IS_LOADING_INTERVENTION_TYPES_LIST_ARCHIVED, false)
      })
  },

  async fetchInterventionType (
    { commit, rootGetters },
    interventionTypeId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
        commit(InterventionTypeMutations.SET_IS_LOADING_INTERVENTION_TYPE, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS + 'Management/InterventionType/GetInterventionTypeById',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                interventionTypeId: interventionTypeId
              }
            }
          )
          .then(res => {
            commit(InterventionTypeMutations.SET_INTERVENTION_TYPE, res.data)
            commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, res)
            commit(InterventionTypeMutations.SET_IS_LOADING_INTERVENTION_TYPE, false)
            resolve(res.data)
          })
          .catch(err => {
            console.error(err)
            commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, err)
            commit(InterventionTypeMutations.SET_IS_LOADING_INTERVENTION_TYPE, false)
            reject(err)
          })
    })
  },

  async fetchInterventionTypeArchived (
    { commit, rootGetters },
    interventionTypeId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
        commit(InterventionTypeMutations.SET_IS_LOADING_INTERVENTION_TYPE, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS + 'Management/InterventionType/GetInterventionTypeArchivedById',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                interventionTypeId: interventionTypeId
              }
            }
          )
          .then(res => {
            commit(InterventionTypeMutations.SET_INTERVENTION_TYPE, res.data)
            commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, res)
            commit(InterventionTypeMutations.SET_IS_LOADING_INTERVENTION_TYPE, false)
            resolve(res.data)
          })
          .catch(err => {
            console.error(err)
            commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, err)
            commit(InterventionTypeMutations.SET_IS_LOADING_INTERVENTION_TYPE, false)
            reject(err)
          })
    })
  },


  /**
   * It creates a new interventionType.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {InterventionTypeResponseDto} interventionType - InterventionTypeResponseDto
   */
   async createInterventionType ( 
    { commit, rootGetters },
    interventionType: InterventionTypeResponseDto
  ): Promise<void> {

    return await new Promise((resolve, reject) => {
    commit(InterventionTypeMutations.SET_IS_CREATING_INTERVENTION_TYPE, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS + 'Management/InterventionType/CreateInterventionType',
        interventionType,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.interventionTypesList.unshift(res.data.data)

        commit(InterventionTypeMutations.SET_INTERVENTION_TYPES_LIST, rootGetters.interventionTypesList)
        commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, res)
        commit(InterventionTypeMutations.SET_IS_CREATING_INTERVENTION_TYPE, false)
        resolve(res.data.data)
      })
      .catch(err => {
        commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, err)
        commit(InterventionTypeMutations.SET_IS_CREATING_INTERVENTION_TYPE, false)
        reject(err)
      })
    })
  },
  /**
   * Update an interventionType
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {InterventionTypeResponseDto} interventionType - InterventionTypeResponseDto
   */
  async updateInterventionType ({ commit, rootGetters }, interventionType: InterventionTypeResponseDto) {
    commit(InterventionTypeMutations.SET_IS_UPDATING_INTERVENTION_TYPE, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/InterventionType/UpdateInterventionType`,
        {...interventionType},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.interventionTypesList.splice(
          rootGetters.interventionTypesList.findIndex(elem => elem.id == interventionType.id),
          1,
          interventionType
        )
        commit(InterventionTypeMutations.SET_INTERVENTION_TYPES_LIST, rootGetters.interventionTypesList)
        commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, res)
        commit(InterventionTypeMutations.SET_IS_UPDATING_INTERVENTION_TYPE, false)
      })
      .catch(err => {
        commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, err)
        commit(InterventionTypeMutations.SET_IS_UPDATING_INTERVENTION_TYPE, false)
      })
  },

  /**
   * It deletes an interventionType from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} interventionTypeId - string
   */
  async deleteInterventionType ({ commit, rootGetters }, interventionTypeId: string) {
    commit(InterventionTypeMutations.SET_IS_DELETING_INTERVENTION_TYPE, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Management/InterventionType/DeleteInterventionType`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          interventionTypeId: interventionTypeId
        }
      })
      .then(res => {
        rootGetters.interventionTypesListArchived.splice(
          rootGetters.interventionTypesListArchived.findIndex(elem => elem.id == interventionTypeId),
          1
        )
        commit(InterventionTypeMutations.SET_INTERVENTION_TYPES_LIST_ARCHIVED, rootGetters.interventionTypesListArchived)
        commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, res)
        commit(InterventionTypeMutations.SET_IS_DELETING_INTERVENTION_TYPE, false)
      })
      .catch(err => {
        commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, err)
        commit(InterventionTypeMutations.SET_IS_DELETING_INTERVENTION_TYPE, false)
      })
  },
  /**
   * It deletes an interventionType from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} interventionTypeId - string
   */
  async archiveInterventionType (
    { commit, rootGetters },
    interventionTypeId: string
  ): Promise<void> {
    commit(InterventionTypeMutations.SET_IS_ARCHIVING_INTERVENTION_TYPE, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Management/InterventionType/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[interventionTypeId]
        }
      )
      .then(res => {
        rootGetters.interventionTypesListArchived.unshift(rootGetters.interventionTypesList.find(elem => elem.id == interventionTypeId ))
        rootGetters.interventionTypesList.splice(
          rootGetters.interventionTypesList.findIndex(
            elem => elem.id == interventionTypeId
          ),
          1
        )
        commit(
          InterventionTypeMutations.SET_INTERVENTION_TYPES_LIST,
          rootGetters.interventionTypesList
        )
        commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, res)
        commit(InterventionTypeMutations.SET_IS_ARCHIVING_INTERVENTION_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, err)
        commit(InterventionTypeMutations.SET_IS_ARCHIVING_INTERVENTION_TYPE, false)
      })
   },

   async restoreInterventionType (
    { commit, rootGetters },
    interventionTypeId: string
  ): Promise<void> {
    commit(InterventionTypeMutations.SET_IS_UPDATING_INTERVENTION_TYPE, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Management/InterventionType/Restore',
        [interventionTypeId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.interventionTypesList.unshift(rootGetters.interventionTypesListArchived.find(elem => elem.id == interventionTypeId ))
        rootGetters.interventionTypesListArchived.splice(
          rootGetters.interventionTypesListArchived.findIndex(
            elem => elem.id == interventionTypeId
          ),
          1
        )
        commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, res)
        commit(InterventionTypeMutations.SET_IS_UPDATING_INTERVENTION_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(InterventionTypeMutations.SET_REQUEST_STATE_INTERVENTION_TYPE, err)
        commit(InterventionTypeMutations.SET_IS_UPDATING_INTERVENTION_TYPE, false)
      })
   }
}
