import { MissionTypeResponseDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../../rootState'
import { MissionTypeMutations } from './mutations'
import { IMissionTypeState } from './states'

export const actions: ActionTree<IMissionTypeState, RootState> = {
  /**
   * It fetches the list of missionTypes from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchMissionTypesList ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(MissionTypeMutations.SET_IS_LOADING_MISSION_TYPES_LIST, true)
      commit(MissionTypeMutations.SET_IS_LOADING_MISSION_TYPES_LIST_ARCHIVED, false)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Management/MissionType/GetMissionTypes',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
          }
        )
        .then(res => {
          commit(MissionTypeMutations.SET_MISSION_TYPES_LIST, res.data)
          commit(MissionTypeMutations.SET_REQUEST_STATE_MISSION_TYPE, res)
          commit(MissionTypeMutations.SET_IS_LOADING_MISSION_TYPES_LIST, false)
          resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          commit(MissionTypeMutations.SET_REQUEST_STATE_MISSION_TYPE, err)
          commit(MissionTypeMutations.SET_IS_LOADING_MISSION_TYPES_LIST, false)
          reject(err)
        })
    })
  },

  

  async fetchMissionType (
    { commit, rootGetters },
    missionTypeId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
        commit(MissionTypeMutations.SET_IS_LOADING_MISSION_TYPE, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS + 'Management/MissionType/GetMissionTypeById',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                missionTypeId: missionTypeId
              }
            }
          )
          .then(res => {
            commit(MissionTypeMutations.SET_MISSION_TYPE, res.data)
            commit(MissionTypeMutations.SET_REQUEST_STATE_MISSION_TYPE, res)
            commit(MissionTypeMutations.SET_IS_LOADING_MISSION_TYPE, false)
            resolve(res.data)
          })
          .catch(err => {
            console.error(err)
            commit(MissionTypeMutations.SET_REQUEST_STATE_MISSION_TYPE, err)
            commit(MissionTypeMutations.SET_IS_LOADING_MISSION_TYPE, false)
            reject(err)
          })
    })
  },
  /**
   * It fetches the missionType data from the API.
   * @param  - missionTypeId: The missionTypeId of the missionType you want to fetch.
   * @param {string} missionTypeId - string
   */ 


  /**
   * It creates a new missionType.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {MissionTypeResponseDto} missionType - MissionTypeResponseDto
   */
   async createMissionType ( 
    { commit, rootGetters },
    missionType: MissionTypeResponseDto
  ): Promise<void> {

    return await new Promise((resolve, reject) => {
    commit(MissionTypeMutations.SET_IS_CREATING_MISSION_TYPE, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS + 'Management/MissionType/CreateMissionType',
        missionType,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.missionTypesList.unshift(res.data.data)

        commit(MissionTypeMutations.SET_MISSION_TYPES_LIST, rootGetters.missionTypesList)
        commit(MissionTypeMutations.SET_REQUEST_STATE_MISSION_TYPE, res)
        commit(MissionTypeMutations.SET_IS_CREATING_MISSION_TYPE, false)
        resolve(res.data.data)
      })
      .catch(err => {
        commit(MissionTypeMutations.SET_REQUEST_STATE_MISSION_TYPE, err)
        commit(MissionTypeMutations.SET_IS_CREATING_MISSION_TYPE, false)
        reject(err)
      })
    })
  },
  /**
   * Update an missionType
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {MissionTypeResponseDto} missionType - MissionTypeResponseDto
   */
  async updateMissionType ({ commit, rootGetters }, missionType: MissionTypeResponseDto) {
    commit(MissionTypeMutations.SET_IS_UPDATING_MISSION_TYPE, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Management/MissionType/UpdateMissionType`,
        {...missionType,workspaceId: rootGetters.workspaceSelected.id},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.missionTypesList.splice(
          rootGetters.missionTypesList.findIndex(elem => elem.id == missionType.id),
          1,
          missionType
        )
        commit(MissionTypeMutations.SET_MISSION_TYPES_LIST, rootGetters.missionTypesList)
        commit(MissionTypeMutations.SET_REQUEST_STATE_MISSION_TYPE, res)
        commit(MissionTypeMutations.SET_IS_UPDATING_MISSION_TYPE, false)
      })
      .catch(err => {
        commit(MissionTypeMutations.SET_REQUEST_STATE_MISSION_TYPE, err)
        commit(MissionTypeMutations.SET_IS_UPDATING_MISSION_TYPE, false)
      })
  },

  /**
   * It deletes an missionType from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} missionTypeId - string
   */
  async deleteMissionType ({ commit, rootGetters }, missionTypeId: string) {
    commit(MissionTypeMutations.SET_IS_DELETING_MISSION_TYPE, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Management/MissionType/DeleteMissionType`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          missionTypeId: missionTypeId
        }
      })
      .then(res => {
        rootGetters.missionTypesListArchived.splice(
          rootGetters.missionTypesListArchived.findIndex(elem => elem.id == missionTypeId),
          1
        )
        commit(MissionTypeMutations.SET_MISSION_TYPES_LIST, rootGetters.missionTypesList)
        commit(MissionTypeMutations.SET_REQUEST_STATE_MISSION_TYPE, res)
        commit(MissionTypeMutations.SET_IS_DELETING_MISSION_TYPE, false)
      })
      .catch(err => {
        commit(MissionTypeMutations.SET_REQUEST_STATE_MISSION_TYPE, err)
        commit(MissionTypeMutations.SET_IS_DELETING_MISSION_TYPE, false)
      })
  },

  async archiveTaskType (
    { commit, rootGetters },
    collaboratorId: string
  ): Promise<void> {
    // commit(TaskTypeMutations.SET_IS_ARCHIVING_TASK_TYPE, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Management/TaskType/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[collaboratorId]
        }
      )
      .then(res => {
        rootGetters.collaboratorsListArchived.unshift(rootGetters.collaboratorsList.find(elem => elem.id == collaboratorId ))
        rootGetters.collaboratorsList.splice(
          rootGetters.collaboratorsList.findIndex(
            elem => elem.id == collaboratorId
          ),
          1
        )
        // commit(
        //   TaskTypeMutations.SET_TASK_TYPES_LIST,
        //   rootGetters.collaboratorsList
        // )
        // commit(TaskTypeMutations.SET_REQUEST_STATE_TASK_TYPE, res)
        // commit(TaskTypeMutations.SET_IS_ARCHIVING_TASK_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        // commit(TaskTypeMutations.SET_REQUEST_STATE_TASK_TYPE, err)
        // commit(TaskTypeMutations.SET_IS_ARCHIVING_TASK_TYPE, false)
      })
   },

   async restoreTaskType (
    { commit, rootGetters },
    collaboratorId: string
  ): Promise<void> {
    // commit(TaskTypeMutations.SET_IS_UPDATING_TASK_TYPE, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Management/TaskType/Restore',
        [collaboratorId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.collaboratorsList.unshift(rootGetters.collaboratorsListArchived.find(elem => elem.id == collaboratorId ))
        rootGetters.collaboratorsListArchived.splice(
          rootGetters.collaboratorsListArchived.findIndex(
            elem => elem.id == collaboratorId
          ),
          1
        )
        // commit(TaskTypeMutations.SET_REQUEST_STATE_TASK_TYPE, res)
        // commit(TaskTypeMutations.SET_IS_UPDATING_TASK_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        // commit(TaskTypeMutations.SET_REQUEST_STATE_TASK_TYPE, err)
        // commit(TaskTypeMutations.SET_IS_UPDATING_TASK_TYPE, false)
      })
   }
}
