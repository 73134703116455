import { IProfessionalContractTypeState } from "./states"
import { Module } from "vuex"
import { ContractResponseDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IProfessionalContractTypeState = {
    professionalContractTypesList : Array<ContractResponseDto>(),
    professionalContractTypesListArchived : Array<ContractResponseDto>(),
    professionalContractType      : <ContractResponseDto>{},
    isLoadingProfessionalContractTypesList    : false,
    isLoadingProfessionalContractTypesListArchived    : false,
    isLoadingProfessionalContractType    : false,
    isCreatingProfessionalContractType   : false,
    isUpdatingProfessionalContractType   : false,
    isDeletingProfessionalContractType   : false,
    requestStateProfessionalContractType : <IRequestState>{},
    isOpenSidebarProfessionalContractType : false,
    isArchivingProfessionalContractType : false,
}

export const ProfessionalContractTypeModule: Module<IProfessionalContractTypeState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}