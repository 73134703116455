import { IWaterTreatmentTypeState } from "./states"
import { Module } from "vuex"
import { WaterTreatmentTypeResponseDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IWaterTreatmentTypeState = {
    waterTreatmentTypesList : Array<WaterTreatmentTypeResponseDto>(),
    waterTreatmentTypesListArchived : Array<WaterTreatmentTypeResponseDto>(),
    waterTreatmentType      : <WaterTreatmentTypeResponseDto>{},
    isLoadingWaterTreatmentTypesList    : false,
    isLoadingWaterTreatmentTypesListArchived    : false,
    isLoadingWaterTreatmentType    : false,
    isCreatingWaterTreatmentType   : false,
    isUpdatingWaterTreatmentType   : false,
    isDeletingWaterTreatmentType   : false,
    requestStateWaterTreatmentType : <IRequestState>{},
    isOpenSidebarWaterTreatmentType : false,
    isArchivingWaterTreatmentType : false,
}

export const WaterTreatmentTypeModule: Module<IWaterTreatmentTypeState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}