import { IInterventionState } from "./states"
import { Module } from "vuex"
import { InterventionResponseDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IInterventionState = {
    interventionsList : Array<InterventionResponseDto>(),
    interventionsListArchived : Array<InterventionResponseDto>(),
    intervention      : <InterventionResponseDto>{},
    isLoadingInterventionsList    : false,
    isLoadingInterventionsListArchived    : false,
    isLoadingIntervention    : false,
    isCreatingIntervention   : false,
    isUpdatingIntervention   : false,
    isUpdatingStepIntervention   : false,
    isDeletingIntervention   : false,
    requestStateIntervention : <IRequestState>{},
    isOpenSidebarIntervention : false,
    isArchivingIntervention : false,
}

export const InterventionModule: Module<IInterventionState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}