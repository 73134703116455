import { IStepState } from "./states"
import { Module } from "vuex"
import { StepResponseDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IStepState = {
    stepsList : Array<StepResponseDto>(),
    commonStepsList: Array<StepResponseDto>(),
    stepsListArchived : Array<StepResponseDto>(),
    commonStepsListArchived : Array<StepResponseDto>(),
    step      : <StepResponseDto>{},
    isLoadingStepsList    : false,
    isLoadingCommonStepsList    : false,
    isLoadingStepsListArchived    : false,
    isLoadingCommonStepsListArchived: false,
    isLoadingStep    : false,
    isCreatingStep   : false,
    isUpdatingStep   : false,
    isUpdatingMissionStep : false,
    isUpdatingMissionStepIntervention : false,
    isDeletingStep   : false,
    requestStateStep : <IRequestState>{},
    requestStateCommonStep : <IRequestState>{},
    isOpenSidebarStep : false,
    isArchivingStep : false,
}

export const StepModule: Module<IStepState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}