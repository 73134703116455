import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { InterventionTypeResponseDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../../rootState'
import { IInterventionTypeState } from './states'


export const getters: GetterTree<IInterventionTypeState, RootState> = {
  interventionTypesList: (state): InterventionTypeResponseDto[] => state.interventionTypesList,
  interventionTypesListArchived: (state): InterventionTypeResponseDto[] => state.interventionTypesListArchived,
  interventionType     : (state): InterventionTypeResponseDto => state.interventionType,

  // COMMON
  isLoadingInterventionTypesList   : (state): boolean => state.isLoadingInterventionTypesList,
  isLoadingInterventionTypesListArchived   : (state): boolean => state.isLoadingInterventionTypesListArchived,
  isLoadingInterventionType   : (state): boolean => state.isLoadingInterventionType,
  isCreatingInterventionType  : (state): boolean => state.isCreatingInterventionType,
  isUpdatingInterventionType  : (state): boolean => state.isUpdatingInterventionType,
  isDeletingInterventionType  : (state): boolean => state.isDeletingInterventionType,
  requestStateInterventionType: (state): IRequestState => state.requestStateInterventionType,
  isOpenSidebarInterventionType : (state): boolean => state.isOpenSidebarInterventionType,
  isArchivingInterventionType : (state): boolean => state.isArchivingInterventionType,
}