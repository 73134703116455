import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { AnalysisResponseDto } from "@/../Api";
import { MutationTree } from "vuex";
import { IAnalysisState } from "./states";

export enum AnalysisMutations {
  SET_ANALYSIS_LIST = "SET_ANALYSIS_LIST",
  SET_ANALYSIS_LIST_ARCHIVED = "SET_ANALYSIS_LIST_ARCHIVED",
  SET_ANALYSE = "SET_ANALYSE",

  // COMMON
  SET_IS_LOADING_ANALYSIS_LIST = "SET_IS_LOADING_ANALYSIS_LIST",
  SET_IS_LOADING_ANALYSIS_LIST_ARCHIVED = "SET_IS_LOADING_ANALYSIS_LIST_ARCHIVED",

  SET_IS_LOADING_ANALYSE = "SET_IS_LOADING_ANALYSE",
  SET_IS_CREATING_ANALYSE = "SET_IS_CREATING_ANALYSE",
  SET_IS_UPDATING_ANALYSE = "SET_IS_UPDATING_ANALYSE",
  SET_IS_DELETING_ANALYSE = "SET_IS_DELETING_ANALYSE",
  SET_REQUEST_STATE_ANALYSE = "SET_REQUEST_STATE_ANALYSE",
  SET_IS_OPEN_SIDEBAR_ANALYSE = "SET_IS_OPEN_SIDEBAR_ANALYSE",
  SET_IS_ARCHIVING_ANALYSE = "SET_IS_ARCHIVING_ANALYSE",
}

export const mutations: MutationTree<IAnalysisState> = {
  [AnalysisMutations.SET_ANALYSIS_LIST]: (state, payload: AnalysisResponseDto[]) => {
    state.analysisList = payload;
  },
  [AnalysisMutations.SET_ANALYSIS_LIST_ARCHIVED]: (state, payload: AnalysisResponseDto[]) => {
    state.analysisListArchived = payload;
  },
  [AnalysisMutations.SET_ANALYSE]: (state, payload: AnalysisResponseDto) => {
    state.analysis = payload;
  },

  //COMMON
  [AnalysisMutations.SET_IS_LOADING_ANALYSIS_LIST]: (state, payload: boolean) => {
    state.isLoadingAnalysisList = payload;
  },
  [AnalysisMutations.SET_IS_LOADING_ANALYSIS_LIST_ARCHIVED]: (state, payload: boolean) => {
    state.isLoadingAnalysisListArchived = payload;
  },
  [AnalysisMutations.SET_IS_LOADING_ANALYSE]: (state, payload: boolean) => {
    state.isLoadingAnalysis = payload;
  },
  [AnalysisMutations.SET_IS_CREATING_ANALYSE]: (state, payload: boolean) => {
    state.isCreatingAnalysis = payload;
  },
  [AnalysisMutations.SET_IS_UPDATING_ANALYSE]: (state, payload: boolean) => {
    state.isUpdatingAnalysis = payload;
  },
  [AnalysisMutations.SET_IS_DELETING_ANALYSE]: (state, payload: boolean) => {
    state.isDeletingAnalysis = payload;
  },
  [AnalysisMutations.SET_REQUEST_STATE_ANALYSE]: (state, payload: IRequestState) => {
    state.requestStateAnalysis = payload;
  },
  [AnalysisMutations.SET_IS_OPEN_SIDEBAR_ANALYSE]: (state, payload: boolean) => {
    state.isOpenSidebarAnalysis = payload;
  },
  [AnalysisMutations.SET_IS_ARCHIVING_ANALYSE]: (state, payload: boolean) => {
    state.isArchivingAnalysis = payload;
  },
};
